import skaliHome from '../../assets/images/projects/project_pages/skali/skali-home.jpg';
import skaliHomeMobile from '../../assets/images/projects/project_pages/skali/skali-home-mobile.jpg';
import skaliAbout from '../../assets/images/projects/project_pages/skali/skali-about.jpg';
import skaliAboutMobile from '../../assets/images/projects/project_pages/skali/skali-about-mobile.jpg';
import skaliVisit from '../../assets/images/projects/project_pages/skali/skali-visit.jpg';
import skaliVisitMobile from '../../assets/images/projects/project_pages/skali/skali-visit-mobile.jpg';
import skaliJacket from '../../assets/images/projects/project_pages/skali/skali-jacket-mockup.jpg';
import skaliLogoProcess01 from '../../assets/images/projects/project_pages/skali/skali-logo-process-01.jpg';
import skaliLogoProcess02 from '../../assets/images/projects/project_pages/skali/skali-logo-process-02.jpg';
import skaliLogoProcess03 from '../../assets/images/projects/project_pages/skali/skali-logo-process-03.jpg';
import skaliLogoProcess04 from '../../assets/images/projects/project_pages/skali/skali-logo-process-04.jpg';
import skaliLogoProcess05 from '../../assets/images/projects/project_pages/skali/skali-logo-process-05.jpg';
import skaliLogoProcess06 from '../../assets/images/projects/project_pages/skali/skali-logo-process-06.jpg';
import skaliLogoProcess07 from '../../assets/images/projects/project_pages/skali/skali-logo-process-07.jpg';
import skaliLogoProcess08 from '../../assets/images/projects/project_pages/skali/skali-logo-process-08.jpg';
import skaliOldSite from '../../assets/images/projects/project_pages/skali/skali-old-site.png';
import skaliOldSiteMobile from '../../assets/images/projects/project_pages/skali/skali-old-site-mobile.png';

import AppleWindow from "../common/AppleWindow";

const skillsData = ["Illustrator", "Figma", "CSS3", "HTML5", "Laravel"];
const skills = skillsData.join(" | ");

const SkaliProject = () => {
   return (
      <div className="project-popup-component">
         <div className="centraliser">
            <header className="section-title">
               <h1>Skali Ski Club</h1>
               <hr></hr>
               <p>Website Redesign</p>
               {skills && <p>{skills}</p>}
            </header>
         </div>
         <div className="centraliser">
            <p>Skali Ski Club is a private club offering accommodation to members and guests in the Mt Baw Baw ski resort. They needed a new logo and website and I was tasked with creating new designs, as well as constructing the pages in HTML and CSS.</p>
            <p>The Laravel framework is used for this site, with a back end admin area similar to WordPress allowing the client to log in and add images or text themselves.</p>
            <p>This site is currently under construction and not yet available to view online</p>
         </div>
         <div className="work-sample-section">
            <figure>
               <img src={skaliLogoProcess08} alt="" />
            </figure>
         </div>
         <div className="centraliser">
            <h2>Logo Design Process</h2>
            <p>I decided to take advantage of the amazing structure and symmetry of a snowflake for this logo.</p>
         </div>
         <div className="work-sample-section less-row-gap">
            <figure>
               <img src={skaliLogoProcess01} alt="" />
            </figure>
         </div>
         <div className="centraliser">
            <p>I searched until I found a good image of a nice snowflake I was happy with that had an interesting shape and system of branches. I wanted the logo to be based on an actual image of a snowflake, to help create something unique and avoid looking like many other snowflake icons that people would have seen.</p>
         </div>
         <div className="work-sample-section less-row-gap">
            <figure>
               <img src={skaliLogoProcess02} alt="" />
            </figure>
         </div>
         <div className="centraliser">
            <p>I began drawing the branches, then experimenting with repeating different branch versions around in a six pointed star. Like a fold painting it looks very different when the repeated shapes are placed next to each other and creates unexpected results in the negative space.</p>
         </div>
         <div className="work-sample-section less-row-gap">
            <figure>
               <img src={skaliLogoProcess03} alt="" />
            </figure>
         </div>
         <div className="centraliser">
            <p>This is the branch I settled on after simplifying it a bit more. The whole flake is a bit complex for a logo though, so it needed to be cut back further.</p>
         </div>
         <div className="work-sample-section less-row-gap">
            <figure>
               <img src={skaliLogoProcess04} alt="" />
            </figure>
         </div>
         <div className="centraliser">
            <p>I really liked this shape in the middle so started making different versions of that.</p>
         </div>
         <div className="work-sample-section less-row-gap">
            <figure>
               <img src={skaliLogoProcess05} alt="" />
            </figure>
         </div>
         <div className="work-sample-section less-row-gap">
            <figure>
               <img src={skaliLogoProcess06} alt="" />
            </figure>
         </div>
         <div className="centraliser">
            <p>After more simplifying and combining the branches together we get to our final snowflake.</p>
         </div>
         <div className="work-sample-section less-row-gap">
            <figure>
               <img src={skaliLogoProcess07} alt="" />
            </figure>
         </div>
         <div className="centraliser">
            <p>The font I chose to use for "Skali" was Magistral, designed at ParaType in 1997 by Dmitry Kirsanov. I think the curvy lines help soften the logo a bit next to the sharp edges of the snowflake, and the vibe of this font somehow suits that of a ski resort. "Ski Club" is written in Sofia Pro created in 2009 by Olivier Gourvat at Adobe Fonts. It's geometric shapes I thought matched well with the squarish letterforms of the S and A in Skali.</p>
            <p>The logo looks better reversed out on blue, and should be displayed like this whenever possible.</p>
         </div>
         <div className="work-sample-section less-row-gap">
            <figure>
               <img src={skaliJacket} alt="" />
            </figure>
         </div>
         <div className="work-sample-section">
            <AppleWindow desktopImg={skaliHome} mobileImg={skaliHomeMobile} />
            <h3 className="caption">Skali / Homepage</h3>
         </div>
         <div className="centraliser">
            <h2>Website Redesign</h2>
         </div>
         <div className="centraliser">
            <p>I continued with the curvy lines and opted for pretty round corners on most things, and also that blue gradient background which looks nice against the white text.</p>
         </div>
         <div className="work-sample-section">
            <AppleWindow desktopImg={skaliAbout} mobileImg={skaliAboutMobile} />
            <h3 className="caption">Skali / About</h3>
         </div>
         <div className="centraliser">
            <p>I decided to go for a sort of a sidebar on the left with smaller images and things the user can interact with and the main content on the right.</p>
         </div>
         <div className="work-sample-section">
            <AppleWindow desktopImg={skaliVisit} mobileImg={skaliVisitMobile} />
            <h3 className="caption">Skali / Plan Your Trip</h3>
         </div>
         <div className="centraliser">
            <p>This page is more of a standard two column layout that is easier for the user to add their own content to later on. It is fairly generic with an image on one side and text on the other.</p>
         </div>
         <div className="work-sample-section">
            <AppleWindow desktopImg={skaliOldSite} mobileImg={skaliOldSiteMobile} />
            <h3 className="caption">Skali / Homepage / Original Website</h3>
         </div>
         <div className="centraliser">
            <h2>Previous Website</h2>
            <p>The old website and logo were a bit outdated and it was definitely time for a refresh.</p>
            <p>The existing site had been up for some time, with a small amount of text talking about the lodge and some of the things to do at Baw Baw during winter and summer. There were some images, and quite a few on facebook but mostly fairly low res unfortunately.</p>
         </div>
                
      </div>
   )
  
}

export default SkaliProject;
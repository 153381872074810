import { useState, useEffect } from 'react';

import scrollIcon from '../../assets/icons/scroll-to-top-arrow.svg';
// import scrollIcon from '../../assets/icons/scroll-to-top-arrow-reversed.svg';

const ScrollToTopBtn = () => {

   const [isVisible, setIsVisible] = useState(false);
   
   const toggleVisibiltiy = () => {
      if(window.scrollY > 1000) {
         setIsVisible(true);
      } else {
         setIsVisible(false);
      }
   }

   const scrollToTop = () => {
      window.scrollTo({top: 0, behavior: 'smooth'});
   }

   useEffect(() => {
      window.addEventListener('scroll', toggleVisibiltiy);

      return () => {
         window.removeEventListener('scroll', toggleVisibiltiy);
      }
   }, [])

   return (
      <div className={`scroll-btn-wrap ${isVisible ? "reveal" : ""}`}>
         <div className={`btn-icon ${isVisible ? "reveal" : ""}`}> 
            <img src={scrollIcon} alt="Scroll up arrow" />  
         </div>
         <button className={`scroll-btn ${isVisible ? "reveal" : ""}`} type='button' onClick={scrollToTop}></button>
      </div>
   )
}

export default ScrollToTopBtn;
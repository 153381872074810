

const ComingSoon = () => {
   return (
      <div className="project-popup-component">
         <div className="centraliser">
            <h1>Coming Soon</h1>
            <p>This page is under construction and will be arriving soon!</p>
         </div>
      </div>
   )
  
}

export default ComingSoon;
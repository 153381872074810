import marrinerFeaturedImage from '../assets/images/projects/project-marriner-group.jpg';
import aiaFeaturedImage from '../assets/images/projects/project-aia.jpg';
import corserFeaturedImage from '../assets/images/projects/project-corser.jpg';
import dingbatsFeaturedImage from '../assets/images/projects/project-dingbats.jpg';
import ecoFeaturedImage from '../assets/images/projects/project-eco.jpg';
import figsAndFetaFeaturedImage from '../assets/images/projects/project-figs-and-feta.jpg';
import focusFeaturedImage from '../assets/images/projects/project-focus-painting.jpg';
import maxwellFeaturedImage from '../assets/images/projects/project-maxwell.jpg';
import paperSamplerFeaturedImage from '../assets/images/projects/project-paper-sampler.jpg';
import remembotFeaturedImage from '../assets/images/projects/remembot-clip.gif';
import skaliFeaturedImage from '../assets/images/projects/project-skali.png';
import tunaFeaturedImage from '../assets/images/projects/project-tuna.jpg';
import vspaceFeaturedImage from '../assets/images/projects/project-vspace.jpg';
import yummoFeaturedImage from '../assets/images/projects/project-yummo.jpg';
import freddFeaturedImage from '../assets/images/projects/project-Fredd-Briggs.jpg';
import envirolineFeaturedImage from '../assets/images/projects/project-enviroline.jpg';
import kerryFeaturedImage from '../assets/images/projects/project-kerry.jpg';
import verbleFeaturedImage from '../assets/images/projects/verble-thumb.jpg';
import jeepsFeaturedImage from '../assets/images/projects/just-jeeps-thumb.jpg';
import havenFeaturedImage from '../assets/images/projects/haven-thumb-02.jpg';


import spaceRocksFeaturedImage from '../assets/images/projects/space-rocks-demo-16x9.gif';
import slimeFeaturedImage from '../assets/images/projects/you-are-slime.gif';

// import ProjectsFeaturedImages from '../assets/images/projects/ProjectsFeaturedImages';

// const { remembotFeaturedImage, yummoFeaturedImage, vspaceFeaturedImage, figsAndFetaFeaturedImage, skaliFeaturedImage, maxwellFeaturedImage, tunaFeaturedImage, corserFeaturedImage, focusFeaturedImage, aiaFeaturedImage, ecoFeaturedImage, paperSamplerFeaturedImage, dingbatsFeaturedImage } = ProjectsFeaturedImages;

const ProjectsData = [
   {
      title: "Verble",
      skills: ["React", "Javascript", "SASS", "HTML5", "Photoshop"],
      description: "My version of Wordle, but with verbs, nouns and is not limited to five letters. The player will soon be able to choose between verbs, nouns and adjectives for their game or all three and adjust the word size limit.",
      featuredImage: verbleFeaturedImage,
      readMore: "",
      externalLink: "",
      playMe: "https://storied-semifreddo-0b1d76.netlify.app/",
      type: ["web", "game"]
   },
   {
      title: "Haven Foundation",
      skills: ["Figma", "SASS", "HTML5", "Laravel", "Illustrator", "Photoshop" ],
      description: "I was tasked with redoing the layout and design for Haven Foundation, a provider of supported accomodation for handicapped residents. I created designs in Figma and built responsive layouts using HTML, SASS, and Javascript.",
      featuredImage: havenFeaturedImage,
      readMore: "haven",
      externalLink: "",
      type: ["web"]
   },
   {
      title: "Remembot",
      skills: ["Next.js", "React", "Javascript", "MongoDB", "SASS", "HTML5", "Illustrator" ],
      description: "A browser-based game of memory built with Next.js. Images are fetched from the Pixabay API. Still a work in progress, more features coming soon such as improved UI, more robot and card versions and different animated backgrounds!",
      featuredImage: remembotFeaturedImage,
      readMore: "",
      externalLink: "",
      playMe: "https://remembot.vercel.app/",
      type: ["web", "game"]
   },
   {
      title: "Skali Ski Club",
      skills: ["Illustrator", "Figma", "SASS", "HTML5", "Laravel"],
      description: "Website make-over and new logo. The new website is still under construction and not yet live.",
      featuredImage: skaliFeaturedImage,
      readMore: "skali",
      externalLink: "https://skali.testme.co/",
      type: ["web", "graphics"]
   },
   {
      title: "Just Jeeps",
      skills: ["Laravel", "HTML5", "SASS", "Javascript"],
      description: "This site received a makeover while staying within existing branding. I created new page layouts, navigation and improved site responsiveness for mobile devices.",
      featuredImage: jeepsFeaturedImage,
      readMore: "coming-soon",
      externalLink: "https://justjeeps.com.au/",
      type: ["web"]
   },
   {
      title: "Marriner Group",
      skills: ["Illustrator", "Figma", "Photoshop"],
      description: "A full redesign of the existing website for Marriner Group, a company responsible for some of Melbourne's well known venues such as the Princess Theatre and The Regent Theatre. The design phase has just finished and the site is now under construction",
      featuredImage: marrinerFeaturedImage,
      readMore: "marriner",
      externalLink: "",
      type: ["web"]
   },
   {
      title: "Yummo",
      skills: ["React", "Express", "Javascript", "Sequelize", "HTML5", "SASS", "Bootstrap" ],
      description: "Full stack development of a recipe finding app. The user can search by dish title or ingredient, create an account and store a list of liked recipes.",
      featuredImage: yummoFeaturedImage,
      readMore: "coming-soon",
      externalLink: "",
      downloadLinks: [{ url: "https://github.com/MichaelGredler/yummo-server.git", text: "Server Repo"}, { url: "https://github.com/MichaelGredler/yummo-client.git", text: "Client Repo"}],
      type: ["web"]
   },
   {
      title: "V-Space Parking Solutions",
      skills: ["WordPress", "SASS", "HTML5", "SEO", "Copywriting", "Photoshop" ],
      description: "A range of work involving web design, copywriting, graphic design and branding.",
      featuredImage: vspaceFeaturedImage,
      readMore: "vspace",
      externalLink: "https://www.vspaceparkers.com.au/",
      type: ["web", "graphics"]
   },
   {
      title: "Kerry & Co Design",
      skills: ["WordPress", "Elementor", "Figma", "SASS", "HTML5", "Javascript"],
      description: "Brand new website and logo for a fabric and textile importing company.",
      featuredImage: kerryFeaturedImage,
      readMore: "coming-soon",
      externalLink: "https://kerrycodesign.com/",
      type: ["web", "graphics"]
   },
   {
      title: "Figs & Feta",
      skills: ["InDesign", "Photoshop", "Illustrator" ],
      description: "Desktop publishing for a lifestyle magazine called Figs & Feta.",
      featuredImage: figsAndFetaFeaturedImage,
      readMore: "figs",
      externalLink: "",
      type: ["graphics"]
   },
   {
      title: "Corser Property Consulting",
      skills: ["Illustrator", "Photoshop"],
      description: "Website layout redesign for a property consulting company.",
      featuredImage: corserFeaturedImage,
      readMore: "coming-soon",
      externalLink: "https://corserproperty.com.au/",
      type: ["web"]
   },
   {
      title: "Fred Briggs",
      skills: ["Illustrator", "Photoshop" ],
      description: "A website redesign for an Australian artist who is known for his drawings and paintings of cars and race car drivers.",
      featuredImage: freddFeaturedImage,
      readMore: "fredd",
      externalLink: "https://fredd.com.au/",
      type: ["graphics"]
   },
   {
      title: "Focus Painting Group",
      skills: ["WordPress", "Divi", "SASS", "HTML5", "Illustrator" ],
      description: "Logo and website design for a Melbourne based painting company.",
      featuredImage: focusFeaturedImage,
      readMore: "coming-soon",
      externalLink: "http://focuspainting.com.au/",
      type: ["web"]
   },   
   {
      title: "Tuna Packaging",
      skills: ["Photoshop", "Illustrator", "Drawing" ],
      description: "Illustration and packaging design for tuna snacks made for kids, a student project while studying at RMIT.",
      featuredImage: tunaFeaturedImage,
      readMore: "coming-soon",
      externalLink: "",
      type: ["graphics"]
   },      
   {
      title: "AIA Annual Report",
      skills: ["InDesign", "Illustrator", "Photoshop"],
      description: "A graphic design practice brief. Created the cover and some inside pages for the Australian Institute of Architecture 2016 annual report.",
      featuredImage: aiaFeaturedImage,
      readMore: "coming-soon",
      externalLink: "",
      type: ["graphics"]
   },
   {
      title: "Enviroline",
      skills: ["Illustrator", "Photoshop", "SASS", "HTML5"],
      description: "A website redesign for a Melbourne-based architect with a focus on sustainablity.",
      featuredImage: envirolineFeaturedImage,
      readMore: "coming-soon",
      externalLink: "https://enviroline.com.au/",
      type: ["web"]
   },
   {
      title: "Maxwell Architects",
      skills: ["WordPress", "Elementor", "SASS", "HTML5" ],
      description: "A custom WordPress theme for a fictitious architectural firm, a personal project.",
      featuredImage: maxwellFeaturedImage,
      readMore: "coming-soon",
      externalLink: "",
      type: ["web"]
   },
   {
      title: "Eco Concrete",
      skills: ["Illustrator", "InDesign" ],
      description: "Logo and stationary for a concreting business.",
      featuredImage: ecoFeaturedImage,
      readMore: "coming-soon",
      externalLink: "",
      type: ["graphics"]
   },
   {
      title: "Paper Sampler",
      skills: ["Photoshop", "Illustrator", "InDesign" ],
      description: "A range of card designs for a paper sampler.",
      featuredImage: paperSamplerFeaturedImage,
      readMore: "coming-soon",
      externalLink: "",
      type: ["graphics"]
   },
   {
      title: "Dingbat Font",
      skills: ["Illustrator", "Drawing" ],
      description: "A complete font of Dingbats using the Illustrator extension Fontself.",
      featuredImage: dingbatsFeaturedImage,
      readMore: "marriner",
      externalLink: "",
      type: ["graphics"]
   },
   {
      title: "Space Rocks",
      skills: ["GameMaker Studio 2" , "Programming"],
      description: "A playable video game - my version of the arcade classic Asteroids, created using the GameMaker language which is similar to Javascript and C++.",
      featuredImage: spaceRocksFeaturedImage,
      readMore: "coming-soon",
      externalLink: "",
      type: ["game"]
   },
   {
      title: "You Are Slime",
      skills: ["GameMaker Studio 2", "Programming"],
      description: "An action-packed 2D platformer video game that I am slowly putting together in my spare time.",
      featuredImage: slimeFeaturedImage,
      readMore: "coming-soon",
      externalLink: "",
      type: ["game"]
   },
   
]

export default ProjectsData;
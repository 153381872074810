import homepageImg from '../../assets/images/projects/project_pages/haven/haven-homepage.jpg';

import AppleWindow from "../common/AppleWindow";


const HavenProject = () => {
   return (
      <div className="project-popup-component">
         <div className="centraliser">
            <header className="section-title">
               <h1>Haven Foundation</h1>
               <hr></hr>
               <p>Website Redesign</p>
               <p>Figma | SASS | HTML5 | Laravel | Illustrator | Photoshop</p>
            </header>
         </div>
         <div className="centraliser">
            <p>The Haven Foundation is dedicated to helping individuals with significant mental health and wellbeing concerns find independence. They are a not for profit organisation and subsidiary of <a href="https://www.mindaustralia.org.au/" target="_blank" rel="noreferrer">Mind Australia</a> that provides community housing, and support from qualified mental health workers.</p>
            <p>I was tasked with creating a new look for their <a href="https://havenfoundation.org.au/home" target="_blank" rel="noreferrer">old website</a> in Figma. After severl meetings the design was approved and I have since created a fully responsive layout for the homepage and functioning dropdown menu. The site is currently still under construction.</p>
         </div>
         <div className="work-sample-section">
            <AppleWindow desktopImg={homepageImg} />
            <h3 className="caption">Marriner Group / Homepage</h3>
         </div>
         
                 
      </div>
   )
  
}

export default HavenProject;
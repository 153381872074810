import React, { useState, useEffect } from 'react';

const ContactIcon = (props) => {

   const { iconsData, randColor } = props;

   // const [ iconHover, setIconHover] = useState("false");
   const [ background, setBackground] = useState("#1a1a1a");

   // useEffect(() => {
   //    changeBackground();
   // }, [iconHover]);

   function changeBackground() {
      setBackground(randColor);
   }

   return (
      <a href={iconsData.href} target="_blank" rel="noreferrer"><span className={`coloured-icon mask ${iconsData.title}`} onMouseEnter={changeBackground} onMouseLeave={changeBackground} style={{backgroundColor: `${background}` }}></span></a>
   )
}

export default ContactIcon;


import figsCovers from '../../assets/images/projects/project_pages/figs_and_feta/figs-and-feta-covers.jpg';
import figsSpread01 from '../../assets/images/projects/project_pages/figs_and_feta/project-pages-1920x1280-01.jpg';
import figsSpread02 from '../../assets/images/projects/project_pages/figs_and_feta/project-pages-1920x1280-02.jpg';
import figsSpread03 from '../../assets/images/projects/project_pages/figs_and_feta/project-pages-1920x1280-03.jpg';
import figsSpread04 from '../../assets/images/projects/project_pages/figs_and_feta/project-pages-1920x1280-04.jpg';
import figsSpread05 from '../../assets/images/projects/project_pages/figs_and_feta/project-pages-1920x1280-05.jpg';
import figsSpread06 from '../../assets/images/projects/project_pages/figs_and_feta/project-pages-1920x1280-06.jpg';
import figsSpread07 from '../../assets/images/projects/project_pages/figs_and_feta/project-pages-1920x1280-07.jpg';
import figsSpread08 from '../../assets/images/projects/project_pages/figs_and_feta/project-pages-1920x1280-08.jpg';
import figsSpread09 from '../../assets/images/projects/project_pages/figs_and_feta/project-pages-1920x1280-09.jpg';
import figsSpread10 from '../../assets/images/projects/project_pages/figs_and_feta/project-pages-1920x1280-10.jpg';
import figsSpread11 from '../../assets/images/projects/project_pages/figs_and_feta/project-pages-1920x1280-11.jpg';
import figsSpread12 from '../../assets/images/projects/project_pages/figs_and_feta/project-pages-1920x1280-12.jpg';

import AppleWindow from "../common/AppleWindow";


const VSpaceProject = () => {
   return (
      <div className="project-popup-component">
         <div className="centraliser">
            <header className="section-title">
               <h1>Figs &amp; Feta Magazine</h1>
               <hr></hr>
               <p>Magazine layout</p>
               <p>InDesign | Photoshop</p>
            </header>
         </div>
         <div className="centraliser">
             <p>Figs &amp; Feta is a home decor business and their lifestyle magazine is the passionate endeavour of company founder Kim La Ferla to share ideas for creating beautiful things, spaces, recipes and experiences with her readers.</p>
         </div>
         <div className="work-sample-section">
            <figure>
               <img src={figsCovers} alt="" />
            </figure>
         </div>
         <div className="centraliser">
            <p>The look and feel here was a mixture of elegant, vibrant, down to earth and fun, with the many different stories each having their own particular style.  </p>
         </div>



         <div className="work-sample-section">
            <figure>
               <img src={figsSpread04} alt="" />
            </figure>
         </div>

         <div className="work-sample-section">
            <figure>
               <img src={figsSpread08} alt="" />
            </figure>
         </div>

         <div className="work-sample-section">
            <figure>
               <img src={figsSpread10} alt="" />
            </figure>
         </div>

         <div className="work-sample-section">
            <figure>
               <img src={figsSpread02} alt="" />
            </figure>
         </div>
                  
         <div className="work-sample-section">
            <figure>
               <img src={figsSpread03} alt="" />
            </figure>
         </div>

         <div className="work-sample-section">
            <figure>
               <img src={figsSpread12} alt="" />
            </figure>
         </div>

         <div className="work-sample-section">
            <figure>
               <img src={figsSpread11} alt="" />
            </figure>
         </div>

         
         <div className="work-sample-section">
            <figure>
               <img src={figsSpread09} alt="" />
            </figure>
         </div>
         <div className="work-sample-section">
            <figure>
               <img src={figsSpread05} alt="" />
            </figure>
         </div>
         <div className="work-sample-section">
            <figure>
               <img src={figsSpread06} alt="" />
            </figure>
         </div>
         <div className="work-sample-section">
            <figure>
               <img src={figsSpread01} alt="" />
            </figure>
         </div>
                 
      </div>
   )
  
}

export default VSpaceProject;
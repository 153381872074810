import homepageImg from '../../assets/images/projects/project_pages/marriner/marriner-homepage.jpg';
import homepageMobileImg from '../../assets/images/projects/project_pages/marriner/marriner-homepage-mobile.jpg';
import packagesPageImg from '../../assets/images/projects/project_pages/marriner/packages-page.jpg';
import theatrePageImg from '../../assets/images/projects/project_pages/marriner/theatre-history-page.jpg';
import showsPageImg from '../../assets/images/projects/project_pages/marriner/shows-and-tickets-page.jpg';

import AppleWindow from "../common/AppleWindow";


const MarrinerProject = () => {
   return (
      <div className="project-popup-component">
         <div className="centraliser">
            <header className="section-title">
               <h1>Marriner Group</h1>
               <hr></hr>
               <p>Website Redesign</p>
               <p>Illustrator | Figma | Photoshop</p>
            </header>
         </div>
         <div className="centraliser">
            <p>Marriner Group are the owners and custodians of some of Melbourne's prized theatres. The Princess Theatre, the Regent Theatre, Forum Melbourne, the Plaza Ballroom and the Comedy Theatre are all part of this family run business dedicated to hosting world class performances and events and preserving our landmark heritage theatres.</p>
            <p>They wanted a website that reflected the elegance and prestige of the iconic venues they represent, as well as keeping in line with existing Marriner Group branding.</p>
            <p>I was asked by the fine people at <a href="https://digitalbridge.com.au/" target="_blank" rel="noreferrer">Digital&nbsp;Bridge</a> to take care of the graphic design and layout.</p>
         </div>
         <div className="work-sample-section">
            <AppleWindow desktopImg={homepageImg} mobileImg={homepageMobileImg} />
            <h3 className="caption">Marriner Group / Homepage</h3>
         </div>
         <div className="centraliser">
            <p>With visually stunning performances providing a wealth of great images, I wanted to create a layout that made the most of this. The full screen slider at the top of the homepage will rotate through what is currently showing at the theatres, using images from the actual performances rather than the advertising banners there previously.</p>
         </div>
         <div className="work-sample-section">
            <AppleWindow desktopImg={showsPageImg} />
            <h3 className="caption">Marriner Group / Shows &amp; Tickets</h3>
         </div>
         <div className="centraliser">
            <p>The background image in the top banner of each page can also be changed by the client as new performances continue to provide more amazing pictures. I am hoping this will contribute to a more dynamic feeling in the same way as shows come and go and there's always something next to go an see.</p>
         </div>
         <div className="work-sample-section">
            <AppleWindow desktopImg={theatrePageImg} />
            <h3 className="caption">Marriner Group / Regent Theatre History</h3>
         </div>
         <div className="centraliser">
            <p>I ended up using a mostly gold colour scheme as it lends itself well to a classy look and feel and also sits well together with images of varying colours without clashing or competing too much.</p>
         </div>
         <div className="work-sample-section">
            <AppleWindow desktopImg={packagesPageImg} />
            <h3 className="caption">Marriner Group / Hospitality Packages</h3>
         </div>
         <div className="centraliser">
            <p>The site is still under construction, with the pages for the Regent and Princess Theatres to be completed next. The pages for these venues and the Comedy Theatre will all be contained within the Marriner Group website, while the Forum Melbourne and Plaza Ballroom have their own separate websites. The best way to present all this information in a way that is easy for the user to navigate needs to be figured out.</p>
         </div>         
      </div>
   )
  
}

export default MarrinerProject;
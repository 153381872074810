import React from 'react';
import {Container, Button} from 'react-bootstrap';



const PageNotFound = (props) => {

   const { navigation } = props;

   return (
      <div className="white-bg">
         <Container>
         <div className="p-5 m-t5 row">
            <div className="d-inline col-12">
               <h4 className="mt-3">404 Page Not Found</h4>
               <p>The page you are looking for could not be found!</p>
               <Button variant="dark" href="/">Home</Button>
               {/* <span onClick={() => navigation("/work")}>Home</span> */}
            </div>
         </div>
      </Container>
      </div>
   )
}

export default PageNotFound;
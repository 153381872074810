import React, { useState, useEffect } from 'react';
import Project from '../common/Project';
import ProjectsData from '../../data/ProjectsData';
import ScrollToTopBtn from '../common/ScrollToTopBtn';
import ProjectPopup from '../common/ProjectPopup';


const Work = (props) => {

   const { randColor, navigation } = props;

   const [projectsFilter, setProjectsFilter] = useState(ProjectsData);
   const [allSelected, setAllSelected] = useState(true);
   const [webSelected, setWebSelected] = useState(false);
   const [graphicsSelected, setGraphicsSelected] = useState(false);
   const [gameSelected, setGameSelected] = useState(false);
   const [changeWhiteBG, setChangeWhiteBG] = useState(false);
   const [hide, setHide] = useState(true);
   const [reveal, setReveal] = useState(false);
   const [background, setBackground] = useState("#1a1a1a");
   const [arrowFade, setArrowFade] = useState(false);
   const [showOverlay, setShowOverlay] = useState(false);
   const [showProject, setShowProject] = useState(false);
   const [fadeWorkContent, setFadeWorkContent] = useState(false)
   const [hideWorkContent, setHideWorkContent] = useState(false)
   const [scrollY, setScrollY] = useState(null);
   const [whichProject, setWhichProject] = useState("");
   const [tags, setTags] = useState([]);
   const [projectCategory, setProjectCategory] = useState("all");


   // const location = useLocation();
   // const locationPath = location.pathname;
   
   useEffect(() => {
      console.log("useEffect Work")

      // console.log("location.pathname: ", locationPath);
      // onCurrentPage(locationPath, "work");

      const handleResizeBG = () => {
          console.log("handleResizeBG")
         setChangeWhiteBG(true)
         setTimeout(() => {
            setHide(false);
         }, 200);
         setTimeout(() => {
            setReveal(true);
            setArrowFade(true);
            setShowOverlay(true);

         }, 400);
       }

      handleResizeBG();

      handleProjectsFilter(projectCategory);
      
      // if(tags.length > 0){

      //    handleProjectsFilter("tag");
      // }

      // if(tags.length < 1) {
      //    handleProjectsFilter("all");
      // }
   }, [tags, projectCategory]);

   const handleAddTag = (newTag) => {
      const stateCopy = [...tags];
      if(!stateCopy.find((item) => item === newTag)){
         stateCopy.push(newTag);
         console.log("OOOOOOOOOOOOOO", stateCopy)
         setTags(stateCopy);
      }
   }

   const handleRemoveTag = (tag) => {
      const stateCopy = tags.filter((item) => item !== tag);
      setTags(stateCopy);
      console.log("handleRemoveTag", stateCopy)
      // if(stateCopy.length < 1){
      //    handleProjectsFilter("tag");
      // } else {
      //    handleProjectsFilter("all");
      // }
   }

    const handleTextColor = (all, web, graphics, game) => {
      console.log("handleTextColor")
      setAllSelected(all);
      setWebSelected(web);
      setGraphicsSelected(graphics);
      setGameSelected(game);
    }

    function handleTagFilter(projects) {
       if(tags.length > 0){
          console.log("TAGS", tags)
         let taggedProjects = [];
         tags.forEach((tag) => {
               const matchingProjects = projects.filter((project) => project.skills.find((projectTag) => projectTag === tag));
               console.log("matchingProjects", matchingProjects)
               taggedProjects = [...new Set([...taggedProjects, ...matchingProjects])];
               taggedProjects = taggedProjects.filter((project) => project.skills.find((projectTag) => projectTag === tag));
         })
         console.log("taggedProjects", taggedProjects)
         setProjectsFilter(taggedProjects);
      } else {
         setProjectsFilter(projects)
      }
   }


    const handleProjectsFilter = (filterItem) => {
       console.log("handleProjectsFilter")
       switch(filterItem) {
         case "all":
            handleTextColor(true, false, false, false);
            handleTagFilter(ProjectsData);
            break;
         case "web":
            handleTextColor(false, true, false, false);
            const webData = ProjectsData.filter(project => project.type.includes("web"));
            // setProjectsFilter(webData);
            handleTagFilter(webData);
            break;
         case "graphics":
            handleTextColor(false, false, true, false);
            const graphicsData = ProjectsData.filter(project => project.type.includes("graphics"));
            // setProjectsFilter(graphicsData);
            handleTagFilter(graphicsData);
            break;
         case "game":
            handleTextColor(false, false, false, true);
            const gamesData = ProjectsData.filter(project => project.type.includes("game"));
            // setProjectsFilter(gamesData);
            handleTagFilter(gamesData);
         break;
         // case "tag":
         //    function handleTagFilter() {
         //       let taggedProjects = [];
         //       console.log("TAGS", tags)
         //       tags.forEach((tag) => {
         //             const matchingProjects = projectsFilter.filter((project) => project.skills.find((projectTag) => projectTag === tag));
         //             console.log("matchingProjects", matchingProjects)
         //             taggedProjects = [...new Set([...taggedProjects, ...matchingProjects])];
         //             taggedProjects = taggedProjects.filter((project) => project.skills.find((projectTag) => projectTag === tag));
         //       })
         //       console.log("taggedProjects", taggedProjects)
         //       setProjectsFilter(taggedProjects);
         //    }

         //    handleTagFilter();
         //    console.log("projectsFilter", projectsFilter)
         //    console.log("projectsFilter", projectsFilter[0].skills.find((tag) => tag === "React"))
         // break;
         default:
            return;
       }
    }


   
    
   function handleChangeBackground() {
      setBackground(randColor);
   }

   function handleBackArrow() {
      setReveal(false);
      setArrowFade(false);
      setShowOverlay(false);
      setTimeout(() => {
         setChangeWhiteBG(false)
      }, 400);
      setTimeout(() => {
         setHide(true);
      }, 450);
      setTimeout(() => {
         navigation("/");
      }, 800);
   }
   
   const handleReadMore = (readMore) => {
      console.log("handleReadMore", readMore);
      setFadeWorkContent(true);
      setScrollY(window.scrollY);
      setWhichProject(readMore);
      setTimeout(() => {
         setHideWorkContent(true);
         setShowProject(true);
      }, 100);
   }
   
   const handleHideProject = () => {
      setShowProject(false);
      console.log(scrollY)
      setHideWorkContent(false);
      setTimeout(() => {
         setFadeWorkContent(false);
      }, 10)
      setTimeout(() => {
         window.scrollTo({
            top: scrollY,
            behavior: 'auto'
         });
      }, 11)
      
   }

   const handleWhichProject = (project) => {
      setWhichProject(project)
   }
   
   
   return (
      <div className={`white-bg-wrap work ${changeWhiteBG ? "resize-white-bg" : ""}`}>
         <div className="white-bg work">

            <ScrollToTopBtn />



            {showProject && <ProjectPopup whichProject={whichProject} onHideProject={handleHideProject} ProjectsData={ProjectsData} />}

            <div className={`workpage-content ${fadeWorkContent ? "fade" : ""} ${hideWorkContent ? "hide" : ""}`}>
               <div className={`gradient-overlay ${showOverlay ? "show-overlay" : ""}`}></div>
               <div className={`back-arrow-wrap work ${arrowFade ? "fade-in" : "fade-out"}`} onClick={handleBackArrow} >
                  <span className="coloured-icon back-arrow" onMouseEnter={handleChangeBackground} onMouseLeave={handleChangeBackground} style={{backgroundColor: `${background}` }}></span>
               </div>

               <div className={`content-wrap work ${reveal && "reveal-work"} ${hide && "hide"}`}>
                  <div className="container work">
                        <h1>Work</h1>
                        <div className="filter">
                           <span className={allSelected ? "color-black" : ""} onClick={() => setProjectCategory("all")}>All</span> /
                           <span className={webSelected ? "color-black" : ""} onClick={() => setProjectCategory("web")}> Web</span> /
                           <span className={graphicsSelected ? "color-black" : ""} onClick={() => setProjectCategory("graphics")}> Graphic Design</span> /
                           <span className={gameSelected ? "color-black" : ""} onClick={() => setProjectCategory("game")}> Game Design</span>
                        </div>
                     
                     <div className="projects-container">
                        {projectsFilter.map((projectData, index) => <Project onRemoveTag={handleRemoveTag} tags={tags} projectData={projectData} featuredImage={projectData.featuredImage} onAddTag={handleAddTag} onReadMore={handleReadMore} onWhichProject={handleWhichProject} key={index} />)}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
  
}

export default Work;
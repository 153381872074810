import freddHomepage from '../../assets/images/projects/project_pages/fredd/fredd-briggs-homepage.jpg';

import AppleWindow from "../common/AppleWindow";


const SkaliProject = () => {
   return (
      <div className="project-popup-component">
         <div className="centraliser">
            <header className="section-title">
               <h1>Fredd Briggs</h1>
               <hr></hr>
               <p>Page Layout</p>
               <p>Illustrator | Photoshop</p>
            </header>
         </div>
         <div className="centraliser">
            <p>Born in New Zealand, Fredd Briggs worked as a freelance illustrator after coming to Australia in 1972. He is well-known for his amazing automotive illustrations and watercolour paintings, depicting hundreds of cars and also some of the biggest names in racing such as Peter Brock, Michael Schumacher, Allan Moffat and others. He's also works for a number of private and corporate clients, including Porsche Cars Australia, Australia Post for which he created a stamp series, and V8 supercar liveries.</p>
         </div>
         <div className="work-sample-section">
            <AppleWindow desktopImg={freddHomepage}  />
         </div>
         <div className="centraliser">
            <p>He wanted a website to showcase his work and also have an online shop where people can buy prints of his drawings. I was involved to create the initial layout which I did in Illustrator, as I had still not made the transition to using Figma. I wanted to keep it pretty clean and minimal to let the work speak for itself, using simple lines and plenty of white space.</p>
         </div>
      </div>
   )
  
}

export default SkaliProject;
import React, { useState, useEffect } from 'react';
import {Container, Button} from 'react-bootstrap';



const ComingSoon = (props) => {

   const { randColor, navigation } = props;
   const [ background, setBackground] = useState("#1a1a1a");
   const [arrowFade, setArrowFade] = useState(false);

   useEffect(() => {
      setArrowFade(true);
    }, []);

    function handleChangeBackground() {
      setBackground(randColor);
   }

   const handleBackArrow = () => {
         navigation("/work");
   }

   return (
      <div className="white-bg-wrap coming-soon">
         <div className="white-bg">
            <div className={`back-arrow-wrap contact ${arrowFade ? "fade-in" : ""}`} onClick={handleBackArrow} >
               <span className="coloured-icon back-arrow" onMouseEnter={handleChangeBackground} onMouseLeave={handleChangeBackground} style={{backgroundColor: `${background}` }}></span>
            </div>
            <div className="content-wrap coming-soon">
                  <h1>Coming Soon!</h1>
                  <p>This page is under construction and will be arriving soon.</p>
            </div>
         </div>
      </div>
   )
}

export default ComingSoon;
import React, { useEffect, useState } from 'react';
import Header from '../layout/Header';
import SocialIcon from '../common/SocialIcon';



const Home = (props) => {

   const { navigation, randColor, homeContentPosition, setHomeContentPosition, navFadeInAtStart, contentFadeInAtStart } = props;

   const [slideUp, setSlideUp] = useState(false);
   const [contentPosition, setContentPositon] = useState("");
   

   useEffect(() => {
      console.log("useEffect Home");
      setTimeout(() => {
         setHomeContentPosition("");
      }, 20);
      
   }, []);

   const iconsData = [
      {
         title: "linkedin",
         href: "https://www.linkedin.com/in/mgredler/"
      },
      {
         title: "behance",
         href: "https://www.behance.net/mGredler"
      },
      {
         title: "github",
         href: "https://github.com/MichaelGredler"
      }
   ]

   const handleExitAnimation = () => {
      console.log("handleExitAnimation")
      setSlideUp(true);
   }

   return (
      <div className="white-bg-wrap">
         <div className="white-bg home">

            <div className={`content-wrap home ${homeContentPosition}`}>
               <Header navigation={navigation} randColor={randColor} onExitAnimation={handleExitAnimation} setHomeContentPosition={setHomeContentPosition} navFadeInAtStart={navFadeInAtStart} />
               
               <div className="container home">
                  <div className="title-wrap">
                     <div className={`lil-shark ${contentFadeInAtStart ? "shark-fade-in" : ""}`}></div>
                     <div className="h1-wrap">
                        <h1 className={contentFadeInAtStart ? "h1-fade-in" : ""}>Michael Gredler</h1>
                     </div>
                     <h2 className={!contentFadeInAtStart ? "h2-fade-in" : ""}>Web Developer / Graphic&nbsp;Designer</h2>
                  </div>
                  <ul className={`social-icons ${navFadeInAtStart ? "social-fade-in" : ""}`}>
                     {iconsData.map((iconsData, index) => <li key={index}><SocialIcon iconsData={iconsData} randColor={randColor} /></li>)}
                  </ul>
               </div>

            </div>
         </div>
      </div>
   )
}

export default Home;
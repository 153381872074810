import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import githubIcon from '../../assets/icons/github-icon.svg';
import TagButton from './TagButton';

const Project = (props) => {

   const { featuredImage, onReadMore, onAddTag, tags, onRemoveTag } = props;
   const { title, skills, description, readMore, externalLink, downloadLinks, playMe } = props.projectData;

   
   
   
   
   const TagList = ({ skills }) => {
      return (
            <ul className="tag-list">
               {skills.map((skill, i) => <li key={i}><TagButton skill={skill} tags={tags} onAddTag={onAddTag} onRemoveTag={onRemoveTag} /></li>)}
            </ul>
         )
    }

    const handleReadMoreBtn = (readMore) => {
      console.log("handleReadMoreBtn", readMore)
      onReadMore(readMore);
    }

   //  const DownloadLinks = () => {
   //     return (
   //    downloadLinks.map((item) => (

   //       <a className="read-more-link github-link" href={item.url} target="_blank" rel="noreferrer">{item.text}</a>
   //       )
         
   //    )
   //  }

       
   return (
      <div className="project-tile">
         <div className="thumb-wrap">
            {externalLink ?
               <a href={externalLink} target="_blank" rel="noreferrer" className="external-link-thumb">
                  <img src={featuredImage} alt=""/>
               </a>

            : playMe ?
               <a href={playMe} target="_blank" rel="noreferrer" className="external-link-thumb">
                  <img src={featuredImage} alt=""/>
               </a>

            : readMore ?
               <div onClick={() => handleReadMoreBtn(readMore)} className="external-link-thumb">
                  <img src={featuredImage} alt="" />
               </div>

            :
               <img src={featuredImage} alt="" />

            }
         </div>
         <div className="details-wrap">
            <div className="text-wrap">
               <h2>{title}</h2>
               <TagList skills={skills} />
               <p>{description}</p>
               {/* {readMoreURL && <Link to={readMoreURL}>Read More</Link>} */}
            </div>
            <div className="read-more-wrap">
               {readMore && <button className="read-more-btn" onClick={() => handleReadMoreBtn(readMore)}>Read More</button>}
               {playMe && <a href={playMe} className="play-me" target="_blank" rel="noreferrer">Play Me</a>}
               {externalLink && <a className="external-link" href={externalLink} target="_blank" rel="noreferrer">
                  <span>Visit Site</span>
                  <span className="img-wrap"></span>
               </a>}
               {downloadLinks && downloadLinks.map((item, i) => <a key={i} className="github-link" href={item.url} target="_blank" rel="noreferrer">
                  <span>{item.text}</span>
                  <span className="img-wrap">
                     {/* <img src={githubIcon} alt="Github icon"/> */}
                  </span>
               </a>)}
            </div>
         </div>
      </div>
   )
}

export default Project;


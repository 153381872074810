import React, { useState, useEffect } from 'react';

import crossImg from '../../assets/icons/cross.svg';
import MarrinerProject from '../project_popups/MarrinerProject';
import VSpaceProject from '../project_popups/VSpaceProject';
import FigsAndFetaProject from '../project_popups/FigsAndFetaProject';
import SkaliProject from '../project_popups/SkaliProject';
import FreddProject from '../project_popups/FreddProject';
import HavenProject from '../project_popups/HavenProject';

import ComingSoon from '../project_popups/ComingSoon';


const ProjectPopup = (props) => {
   
   const { randColor, navigation, onHideProject, whichProject, ProjectsData } = props;
   
   const [reveal, setReveal] = useState(false);
   const [background, setBackground] = useState("#1a1a1a");
   const [crossFade, setCrossFade] = useState(false);
   const [showMarriner, setShowMarriner] = useState(false);
   const [showVspace, setShowVspace] = useState(false);
   const [showFigs, setShowFigs] = useState(false);
   const [showSkali, setShowSkali] = useState(false);
   const [showFredd, setShowFredd] = useState(false);
   const [showHaven, setShowHaven] = useState(false);
   const [showComingSoon, setShowComingSoon] = useState(false);
   
   useEffect(() => {
      // window.scrollTo({
      //    top: 0,
      //    left: 0,
      //    behavior: 'auto'
      // });
      setReveal(true);
      setCrossFade(true)
      handleShowWhichProject(whichProject);
      console.log("popup useEffect", whichProject)
   }, []);

    
   function handleChangeBackground() {
      setBackground(randColor);
   }

   function handleCross() {
      setReveal(false);
      setCrossFade(false);
      setTimeout(() => {
         onHideProject();
      }, 100);
   }

   

   const handleShowWhichProject = (project) => {
      switch(project) {
         case "marriner":
            console.log("SWITCH", project)
            setShowMarriner(true);
            break;
         case "vspace":
            console.log("SWITCH", project)
            setShowVspace(true);
            break;
         case "figs":
            console.log("SWITCH", project)
            setShowFigs(true);
            break;
         case "skali":
            console.log("SWITCH", project)
            setShowSkali(true);
            break;
         case "fredd":
            console.log("SWITCH", project)
            setShowFredd(true);
            break;
         case "haven":
            console.log("SWITCH", project)
            setShowHaven(true);
            break;
         case "coming-soon":
            console.log("SWITCH", project)
            setShowComingSoon(true);
            break;
         default:
            return;
      }
   }
   
   return (
      <div className="project-popup-wrap">
         <div className={`white-bg project-popup ${reveal && "reveal"}`}>

            <div className={`cross ${crossFade ? "fade-in" : ""}`} onClick={handleCross} >
               <img src={crossImg} alt="" />
            </div>


            <div className={"content-wrap project-popup"}>
               <div className="another-wrap">
                  {showMarriner && <MarrinerProject />}
                  {showVspace && <VSpaceProject />}
                  {showFigs && <FigsAndFetaProject />}
                  {showSkali && <SkaliProject />}
                  {showFredd && <FreddProject />}
                  {showHaven && <HavenProject />}

                  {showComingSoon && <ComingSoon />}
               </div>
            </div>
          </div>
      </div>
   )
  
}

export default ProjectPopup;
import React, { useState, useEffect } from 'react';

const TagButton = ({ skill, tags, onAddTag, onRemoveTag }) => {

   const [selected, setSelected] = useState(false);

   useEffect(() => {
      if(tags.find((item) => item === skill)) {
         console.log("tags!!!!!!!!!", tags)
         setSelected(true)
      } else {
         setSelected(false);
         
      }
   }, [tags])
   
   
   const handleTagBtn = (skill) => {
      if(tags.find((item) => item === skill)) {
         console.log("HANDLETAGBTN - remove")
         onRemoveTag(skill);
      } else {
         console.log("HANDLETAGBTN - add")
         onAddTag(skill)
      }
   }

   return (
      <button className={selected ? "selected" : ""} onClick={() => handleTagBtn(skill)}>{skill}</button>
   )
}

export default TagButton;
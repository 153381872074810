import { useState } from 'react';
import arrow from '../../assets/icons/scroll-to-top-arrow-white.svg';


const AppleWindow = (props) => {

    const { desktopImg, mobileImg, testclass } = props;

    const [scrollActive, setScrollActive] = useState(false);

    function handleActivateScroll() {
        setScrollActive(!scrollActive);
    }

//    const { image } = props;
    // const project = "marriner";

// function resizeListener() {
//   heightOutput.textContent = window.innerHeight;
//   widthOutput.textContent = window.innerWidth;
// }

// window.addEventListener("resize", resizeListener);

   return (
    <div className={`apple-window-wrap ${testclass}`}>
        <div className="apple-navbar">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
        </div>
        <div className={`screen ${scrollActive ? "scroll-active" : ""}`} onClick={handleActivateScroll}>
            {/* <img src={image} alt="" /> */}
            {/* <div className={`webpage-image ${project}`}></div> */}
            {/* <img 
                srcset={`${mobileImage} 1023w, ${desktopImage} 1024w`}
                sizes="(max-width: 1023px) 1023px, 1024px"
                src={desktopImage}
                alt="" 
            /> */}


            <div className="scroll-overlay" onClick={handleActivateScroll}>
                <div>
                    <span>Tap to enable scroll</span>
                    <div className="arrow">
                        <img src={arrow} alt="" />
                    </div>

                </div>
            </div>

            <div className="scroll-me-overlay">
                <div>
                    <span>Scroll me</span>
                    <div className="arrow">
                        <img src={arrow} alt="" />
                    </div>

                </div>
            </div>

            {mobileImg ? 
                <picture>
                    <source 
                        srcSet={desktopImg}
                        media="(min-width: 601px)"
                    />
                    <img 
                        src={mobileImg} 
                        alt=""
                    />
                </picture>
            :
                <img 
                    src={desktopImg} 
                    alt=""
                />
            }

        </div>
    </div>
   )
}

export default AppleWindow;


import React, { useState, useEffect } from 'react';

const SocialIcon = (props) => {

   const { iconsData, randColor } = props;

   return (
      <a href={iconsData.href} target="_blank" rel="noreferrer"><span className={`social-icon mask ${iconsData.title}`}></span></a>
   )
}

export default SocialIcon;


import React, { useState, useEffect } from 'react';
// import Navbar from 'react-bootstrap/Navbar';


import { Link } from 'react-router-dom';

const Header = (props) => {

   const { navigation, randColor, setHomeContentPosition, navFadeInAtStart } = props;

   const [navigateTo, setNavigateTo] = useState(null);
   const [background, setBackground] = useState("#1a1a1a");
   const [active, setActive] = useState(false)
   const [slide01, setSlide01] = useState(false);
   const [slide02, setSlide02] = useState(false);
   const [slide03, setSlide03] = useState(false);
   const [aboutLinkClicked, setAboutLinkClicked] = useState(false);
   const [workLinkClicked, setWorkLinkClicked] = useState(false);
   const [contactLinkClicked, setContactLinkClicked] = useState(false);

   useEffect(() => {
      if(navigateTo){
         navigation(navigateTo)
      }
   }, [navigateTo]);

   function changeBackground() {
      setBackground(randColor);
   }


   // function handleActive() {
   //    setActive(!active);
   //    console.log("AAAAAAAAAAAAAAAAAAa")
   // }

   // const handleMenuSlide = () => {
   //    setTimeout(() => {
   //       if(!slide01) {
   //          setSlide01(true)
   //       } else {
   //          setTimeout(() => {
   //             setSlide01(false);
   //          }, 200)
   //       }
   //    }, 20);
   //    setTimeout(() => {
   //       if(!slide02) {
   //          setSlide02(true)
   //       } else {
   //          setTimeout(() => {
   //             setSlide02(false);
   //          }, 200)
   //       }
   //    }, 80);
   //    setTimeout(() => {
   //       if(!slide03) {
   //          setSlide03(true)
   //       } else {
   //          setTimeout(() => {
   //             setSlide03(false);
   //          }, 200)
   //       }
   //    }, 140);
   // }
   

   // const handleMobileMenu = () => {
      
   //    setActive(!active);

   //    setTimeout(() => {
   //       if(!slide01) {
   //          setSlide01(true)
   //       } else {
   //          setTimeout(() => {
   //             setSlide01(false)
   //          }, 30);
   //       }
   //    }, 20);
   //    setTimeout(() => {
   //       if(!slide02) {
   //          setSlide02(true)
   //       } else {
   //          setTimeout(() => {
   //             setSlide02(false)
   //          }, 30);
   //       }
   //    }, 80);
   //    setTimeout(() => {
   //       if(!slide03) {
   //          setSlide03(true)
   //       } else {
   //          setTimeout(() => {
   //             setSlide03(false)
   //          }, 30);
   //       }
   //    }, 140);
   // }

   // const handleMobileMenu = () => {
      
   //    setActive(!active);

   //       if(!slide01) {
   //          setSlide01(true)
   //       } else {
   //          setTimeout(() => {
   //             setSlide01(false)
   //          }, 30);
   //       }

   //       if(!slide02) {
   //          setSlide02(true)
   //       } else {
   //          setTimeout(() => {
   //             setSlide02(false)
   //          }, 30);
   //       }

   //       if(!slide03) {
   //          setSlide03(true)
   //       } else {
   //          setTimeout(() => {
   //             setSlide03(false)
   //          }, 30);
   //       }
   // }

   const handleMobileMenu = () => {
      
      setActive(!active);
      setSlide01(!slide01)
      setSlide02(!slide02)
      setSlide03(!slide03)

   }

   const handleClickedLink = (link) => {
      switch(link) {
         case "about":
            setAboutLinkClicked(true);
            break;
         case "work":
            setWorkLinkClicked(true);
            break;
         case "contact":
            setContactLinkClicked(true);
            break;
         default:
            return;
      }
   }

   const handleAboutBtn = () => {
      setHomeContentPosition("content-right")
      handleClickedLink("about");
      setActive(!active);
      setTimeout(() => {
         navigation("/about");
      }, 200);
   }

   const handleWorkBtn = () => {
      setHomeContentPosition("content-up")
      handleClickedLink("work");
      setActive(!active);
      setTimeout(() => {
         navigation("/work");
      }, 200);
   }

   const handleContactBtn = () => {
      setHomeContentPosition("content-left")
      handleClickedLink("contact");
      setActive(!active);
      setTimeout(() => {
         navigation("/contact");
      }, 200);
   }

   return (
      <header className={`main-nav ${navFadeInAtStart ? "nav-fade-in" : ""}`}>
         <nav className={active ? "active" : ""}>
            <ul>
               <li className={`${slide01 ? "slide-in-01" : ""} grid-item item-01`}>
                  <span className={`about ${aboutLinkClicked ? "link-clicked" : ""}`} onClick={handleAboutBtn}>About</span>
               </li>
               <li className={`${slide02 ? "slide-in-02" : ""} grid-item item-02`}>
                  <span className={`work ${workLinkClicked ? "link-clicked" : ""}`} onClick={handleWorkBtn}>Work</span>
               </li>
               <li className={`${slide03 ? "slide-in-03" : ""} grid-item item-03`}>
                  <span className={`contact ${contactLinkClicked ? "link-clicked" : ""}`} onClick={handleContactBtn}>Contact</span>
               </li>
            </ul>
         </nav>
         <div className={`burger ${active ? "active" : ""}`} onClick={handleMobileMenu}>
			  <span className="bar"></span>
			  <span className="bar"></span>
			  <span className="bar"></span>
		   </div>
      </header>
   )
}

export default Header;
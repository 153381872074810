import homepageImg from '../../assets/images/projects/project_pages/vspace/vspace-homepage.jpg';
import homepageMobileImg from '../../assets/images/projects/project_pages/vspace/vspace-homepage-mobile.png';
import datasheetsPhoneApp from '../../assets/images/projects/project_pages/vspace/vspace-datasheet-and-phone-app.jpg';
import vspaceBusinessCards from '../../assets/images/projects/project_pages/vspace/vspace-business-cards.jpg';
import symposium from '../../assets/images/projects/project_pages/vspace/symposium-booth-Kirlos-and-Kit.jpg';

import AppleWindow from "../common/AppleWindow";

const skillsData = ["WordPress", "CSS3", "HTML5", "SEO", "Copywriting", "Photoshop"];
const skills = skillsData.join(" | ");

const VSpaceProject = () => {
   return (
      <div className="project-popup-component">
         <div className="centraliser">
            <header className="section-title">
               <h1>V-Space Parking Solutions</h1>
               <hr></hr>
               <p>Website Redesign, Branding, SEO</p>
               {skills && <p>{skills}</p>}
            </header>
         </div>
         <div className="centraliser">
             <p>I have been working for V-Space since they started in 2018. Initially involved to create product datasheets and diagrams for their parking machines, I have since been responsible for the development of their branding and website.</p>
         </div>
         <div className="work-sample-section">
            <figure>
               <img src={vspaceBusinessCards} alt="" />
               <figcaption className="caption">V-Space Logo</figcaption>
            </figure>
         </div>
         <div className="centraliser">
            <p>The icon in the original version of the logo involved the use of a v-shaped tick ticking a box, which needed work to prevent it from seeming tacky. It was a requirement to keep the tick, and also have it read easily enough as the letter "V" so it looks like it says "V-Space" and not just "Space Parking Solutions". I also wanted the icon to work well on it's own with no text. The blue was already in the first logo, but that turned into a richer, stronger navy that seemed to sit well with their chunky machines and and selling-points of reliability and sturdiness.</p>
         </div>
         <div className="work-sample-section">
            <div className="static-apple-window">
               <AppleWindow desktopImg={homepageImg} mobileImg={homepageMobileImg} />
               <a className="window-link-wrap" href="https://www.vspaceparkers.com.au" target="_blank" rel="noreferrer">Visit Website</a>
            </div>
               {/* <h3 className="caption">Homepage</h3> */}
               <a className="external-link" target="_blank" rel="noreferrer" href="https://www.vspaceparkers.com.au">Visit Site</a>
         </div>
         
         <div className="centraliser">
            <p>This website was built using WordPress and the Divi theme, though all the CSS was done in a stylesheet, not using Divi's settings. There was no content to begin with, and the generation of text , images and page structure was a lengthy process. English being a second language for the guys at V-Space meant I was able to lend a hand with the copywriting, and also helped describe some fairly technical things from a layman's perspective to make it more readable.</p>
         </div>
         <div className="work-sample-section">
            <div className="video-tablet">
               <iframe className="youtube-embed" src="https://www.youtube.com/embed/IlTpKceB0rA" caption="Car Stacker Tips #1 - How To Use A Car Stacker" title="How To Use A Car Stacker" frameborder="1" allowfullscreen="1"></iframe>
            </div>
            <a className="external-link" target="_blank" rel="noreferrer" href="https://www.vspaceparkers.com.au/video-how-to-use-a-car-stacker/">Video Blog Post</a>
         </div>

         <div className="centraliser">
            <p>SEO was another big part of this job, as they wanted the site to rank up near the top of page 1 in Google's search results. I did some research and competitor analysis using Ahrefs, got a list of keywords and some ideas for content and created the blog. I employed some best practice on-page SEO throughout the rest of the site and it now ranks in the top 5 for most car stacker related queries.</p>
         </div>
         <div className="work-sample-section">
            <figure>
               <img src={datasheetsPhoneApp} alt="" />
               <figcaption className="caption">Datasheets &amp; Mobile App Branding</figcaption>
            </figure>
         </div>
         <div className="centraliser">
            <p>The branding has been rolled out across a number of other different things including printed datasheets, flyers, stickers, a mobile phone app that users use to get their cars, powerpoint presentations, and signage and other graphics for their booth and promotional items at last year's Strata Community Association Symposium.</p>
         </div>
         <div className="work-sample-section">
            <figure>
               <img src={symposium} alt="" />
               <figcaption className="caption">The guys at the Strata Community Association Symposium 2021</figcaption>
            </figure>
         </div>         
      </div>
   )
  
}

export default VSpaceProject;
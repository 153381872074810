import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Import Pages
import Home from './components/pages/Home';
import Work from './components/pages/Work';
// import MarrinerProject from './components/pages/projects/MarrinerProject';
import About from './components/pages/About';
import Contact from './components/pages/Contact';
import ComingSoon from './components/pages/ComingSoon';
import PageNotFound from './components/pages/PageNotFound';

import Popup from './components/common/Popup';


function App(props) {

  // const [currentPage, setCurrentPage] = useState("");
  // const [currentPath, setCurrentPath] = useState("");
  const [homeContentPosition, setHomeContentPosition] = useState("");
  const [navFadeInAtStart, setNavFadeInAtStart] = useState(false);
  const [contentFadeInAtStart, setContentFadeInAtStart] = useState(false);
  const [innerHeight, setInnerHeight] = useState(null);

  const navigation = useNavigate();
  // const location = useLocation();
  // const locationPath = location.pathname;
  
  // const handleCurrentPage = useCallback(
  //   (path) => {
  //     console.log("handleCurrentPage:", path)
  //     // setCurrentPage(page);
  //     setCurrentPath(path);
  //   }, []
  // )

  useEffect(() => {
    console.log("useEffect App.js")

    const innerHeight = window.innerHeight;
  
      setInnerHeight(innerHeight);

    console.log("AAAAAAAAAAAAA", innerHeight)
    setNavFadeInAtStart(true);
    setTimeout(() => {
      setContentFadeInAtStart(true);

    }, 300)
    // handleCurrentPage(locationPath);
  }, []);
  
 
  const randColor = () => {
    console.log("randColor")
    const rand = (min, max) => {
      return Math.floor(Math.random() * (max - min + 1) + min);
    }
  
    const minRGB = 80;
    const maxRGB = 255;
  
    let rgbValue = `rgb(${rand(minRGB, maxRGB)}, ${rand(minRGB, maxRGB)}, ${rand(minRGB, maxRGB)})`;
    return rgbValue;
  }

  

  
  return (
    <div className="App">
      
      <Helmet>
        <meta charSet="utf-8" />
        <title>Michael Gredler</title>
        <link rel="canonical" href="https://michaelgredler.com.au" />
      </Helmet>

      <div className="coloured-bg">
      {/* <div className="coloured-bg" style={{minHeight: `${innerHeight}px`}}> */}
        <div className="lines"></div>
        {/* {currentPage &&
          <div className="handlepage-test">
            <p>{currentPage}</p>
          </div>
        }
        {currentPath &&
          <div className="handlepage-test2">
            <p>{currentPath}</p>
          </div>
        } */}
          <Routes>
            <Route path="/work" element={<Work randColor={randColor} navigation={navigation} />} />
            {/* <Route path="/marriner-group" element={<MarrinerProject randColor={randColor} navigation={navigation} />} /> */}
            <Route path="/about" element={<About randColor={randColor} navigation={navigation}  />} />
            <Route path="/contact" element={<Contact randColor={randColor} navigation={navigation}  />} />
            <Route path="/coming-soon" element={<ComingSoon randColor={randColor} navigation={navigation}  />} />
            
            <Route path="/" element={<Home navigation={navigation} randColor={randColor} homeContentPosition={homeContentPosition} setHomeContentPosition={setHomeContentPosition} navFadeInAtStart={navFadeInAtStart} contentFadeInAtStart={contentFadeInAtStart} />} />
            <Route path="/pagenotfound" element={<PageNotFound navigation={navigation} />} />
            <Route path="/*" element={<PageNotFound navigation={navigation} />} />
          </Routes>
        </div>

      <Popup />
      
    </div>
  );
}

export default App;

import React, { useEffect } from 'react';

const IconBar = (props) => {

   const { barsSlideIn, stat, iconsRevealed } = props;

   return (
      <div className="stats-wrap">
         <div className="icon-wrap">
            <img className={`about-icons ${iconsRevealed && "icons-revealed"}`} src={stat.icon} alt="" />
         </div>
         <div className={`stats-bar-wrap ${stat.title}`}>
            <h5>{stat.title}</h5>
            <div className="stats-bar">
               <div className={`stats-bar-inner ${barsSlideIn && "bars-slide-in"}`}></div>
               <div className="stats-bar-border"></div>
            </div>
         </div>
      </div>
   )
}

export default IconBar;


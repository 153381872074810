const Popup = (props) => {

   return (
      <div className="popup-container">
         <div className="popup-wrap">
            <h2>Sorry!</h2>
            <p>This site is under construction and not yet fully responsive.</p>
            <span>Please view on a 1601px wide screen or larger</span>
         </div>
      </div>
   )
}

export default Popup;


const ContactIconsData = [
   
   {
      title: "envelope",
      href: "mailto:mdgredler@gmail.com"
   },
   {
      title: "linkedin",
      href: "https://www.linkedin.com/in/mgredler/"
   },
   {
      title: "behance",
      href: "https://www.behance.net/mGredler"
   },
   {
      title: "facebook",
      href: "https://www.facebook.com/mikeygredler"
   }
   
]

export default ContactIconsData;
import React, { useState, useEffect } from 'react';
import ContactIcon from '../common/ContactIcon';
import iconsData from '../../data/ContactIconsData';
import monkey from '../../assets/images/customer-support-monkey.svg';

const Contact = (props) => {

   const { randColor, navigation } = props;
   const [ background, setBackground] = useState("#1a1a1a");
   const [arrowFade, setArrowFade] = useState(false);
   const [contentPosition, setContentPosition] = useState("content-right");

   useEffect(() => {
      console.log("useEffect Contact")
      setArrowFade(true);
      setTimeout(() => {
         setContentPosition("");
      }, 20);
    }, []);


   function handleChangeBackground() {
      setBackground(randColor);
   }

   const handleBackArrow = () => {
      setContentPosition("content-right");
      setTimeout(() => {
         navigation("/");
      }, 50);
   }

   return (
      <div className="white-bg-wrap">
         <div className="white-bg">
            <div className={`back-arrow-wrap contact ${arrowFade ? "fade-in" : ""}`} onClick={handleBackArrow} >
               <span className="coloured-icon back-arrow" onMouseEnter={handleChangeBackground} onMouseLeave={handleChangeBackground} style={{backgroundColor: `${background}` }}></span>
            </div>
            <div className="content-wrap contact">
               <div className="container contact">
                  <h1>Contact</h1>
                  <div className="arrow-icons-container">
                     <div className="col-1">
                        <div className="big-arrow-outer">
                           <div className="big-arrow-wrap">
                              <div className="pointer-head"></div>
                           </div>
                        </div>
                     </div>
                     <div className="col-2">
                        <ul className="contact-icons-wrap contact">
                           {iconsData.map((iconsData, index) => <li key={index}><ContactIcon iconsData={iconsData} randColor={randColor} /></li>)}
                        </ul>
                     </div>
                  </div>
                  <p>Don't hesitate to get in touch using your preferred method of contact! I am looking for ongoing work and I'm also available for freelance jobs big or small. I look forward to hearing from you.</p>
               </div>
               <div className={`monkey-container ${contentPosition}`}>
                  <div className="monkey-wrap">
                     <img src={monkey} alt="Monkey with a phone" />
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default Contact;
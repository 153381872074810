import React, { useEffect, useState } from 'react';
import IconBar from '../common/IconBar';

// Image imports
import myPortrait from '../../assets/images/me-BnW-1000px.jpg';
import pdfIcon from '../../assets/images/pdf-icon.svg';
import fatArrow from '../../assets/images/fat-arrow.svg';
import catAndMouse from '../../assets/images/cat-and-mouse.svg';
import html5_icon from '../../assets/icons/html5-icon.svg';
import css_icon from '../../assets/icons/css3-icon.svg';
import react_icon from '../../assets/icons/react-icon.svg';
import wordpress_icon from '../../assets/icons/wordpress-icon.svg';
import seo_icon from '../../assets/icons/magnifying-glass-icon.svg';
import photoshop_icon from '../../assets/icons/photoshop-icon.svg';
import illustrator_icon from '../../assets/icons/illustrator-icon.svg';
import indesign_icon from '../../assets/icons/indesign-icon.svg';
import drawing_icon from '../../assets/icons/hand-drawing-icon.svg';
import resume from '../../assets/mgredler-portfolio.pdf';

const About = (props) => {

   const { randColor, navigation } = props;

   const [barsSlideIn, setBarsSlideIn] = useState(false);
   const [iconsRevealed, setIconsRevealed] = useState(false);
   const [buttonBackground, setButtonBackground] = useState("#ffffff");
   const [buttonAnimation, setButtonAnimation] = useState(false);
   const [resumeBtnHover, setResumeBtnHover] = useState(false);
   const [explosionHover, setExplosionHover] = useState(false);
   const [background, setBackground] = useState("#1a1a1a");
   const [contentPosition, setContentPosition] = useState("content-left");
   const [arrowFade, setArrowFade] = useState(false);


   useEffect(() => {
      const handlePageLoad = () => {
         setArrowFade(true);
         setTimeout(() => {
            setContentPosition("");
         }, 20);
         setTimeout(() => {
            animateIcons();
         }, 100);
         setTimeout(() => {
            console.log("handlePageLoad")
            animateBars();
         }, 300);
      }

      handlePageLoad();
      
   }, []);
   


   function animateBars() {
      console.log("animateBars")
      setBarsSlideIn(true);
   }
   function animateIcons() {
      console.log("animateIcons")
      setIconsRevealed(true);
   }

   const statsData = [
      {
         title: "html",
         icon: html5_icon
      },
      {
         title: "css",
         icon: css_icon
      },
      {
         title: "react",
         icon: react_icon
      },
      {
         title: "wordpress",
         icon: wordpress_icon
      },
      {
         title: "seo",
         icon: seo_icon
      }
   ]

   const statsData2 = [
      {
         title: "photoshop",
         icon: photoshop_icon
      },
      {
         title: "illustrator",
         icon: illustrator_icon
      },
      {
         title: "indesign",
         icon: indesign_icon
      },
      {
         title: "drawing",
         icon: drawing_icon
      }
   ]

   
    

    
   function changeBackground() {
      setButtonBackground(randColor);
   }

   function explosionOnHover(boolean) {
      setExplosionHover(boolean);
   }

   function handleChangeBackground() {
      setBackground(randColor);
   }

   const handleBackArrow = () => {
      setContentPosition("content-left");
      setTimeout(() => {
         navigation("/");
      }, 50);
   }

   return (
      <div className="white-bg-wrap">
         <div className="white-bg">
         <div className={`back-arrow-wrap about ${arrowFade ? "fade-in" : ""}`} onClick={handleBackArrow} >
               <span className="coloured-icon back-arrow" onMouseEnter={handleChangeBackground} onMouseLeave={handleChangeBackground} style={{backgroundColor: `${background}` }}></span>
            </div>
            <div className={`content-wrap about ${contentPosition}`}>
               <div className="cat-img-wrap">
                     <img src={catAndMouse} alt="Cat with a computer mouse" />
               </div>

               <div className="about-grid">
                  {/* PORTRAIT */}
                  <div className="grid-item grid-item-1">
                     <div className="me-img-wrap">
                        <img src={myPortrait} alt="Michael Gredler's portrait" />
                     </div>
                  </div>
                  {/* ABOUT */}
                  <div className="grid-item grid-item-2">
                     <div className="title-wrap">
                        <h1>A Bit About Me</h1>
                        <div className="rule"></div>
                     </div>
                     <div className="text-wrap">
                        <p>Hi, I'm Michael, recent web development graduate and freelance graphic designer. I love designing and building things.</p>
                        <p>I finished studying graphic design at RMIT in 2016 and soon became interested in web design. I began creating small WordPress sites while also undertaking graphic design work, also helping clients with their content and copywriting on occasion.</p>
                        <p>Lockdown presented a good opportunity to pursue a newfound interest in coding and lead onto my returning to study web development at Holmesglen TAFE.</p>
                        <p>I now do a mixture of design and development work, creating designs in Figma and then turning them into responsive web pages.</p>
                     </div>
                  </div>
                  {/* THINGS */}
                  <div className="grid-item grid-item-3">
                     <div className="title-wrap">
                        <h2>Things I Do</h2>
                        <div className="rule"></div>
                     </div>
                     <div className="stats-list-wrap">
                        <ul className="stats-list">
                              {statsData.map((stat, index) => <li key={index}><IconBar barsSlideIn={barsSlideIn} iconsRevealed={iconsRevealed} stat={stat} /></li>)}
                        </ul>
                        <ul className="stats-list">
                              {statsData2.map((stat, index) => <li key={index}><IconBar barsSlideIn={barsSlideIn} iconsRevealed={iconsRevealed} stat={stat} /></li>)}
                        </ul>
                     </div>
                  </div>
                  <div className="grid-item grid-item-4">
                     <div className="cat-img-wrap mobile">
                        <img src={catAndMouse} alt="Cat with a computer mouse" />
                     </div>
                  </div>
                  {/* CAT */}
               </div>

               
               
               {/* RESUME BUTTON */}
               <div className={`resume-btn-wrap ${explosionHover ? "animate-explosion" : "explosion-bg"}`} onMouseEnter={() => explosionOnHover(true)} onMouseLeave = {() => explosionOnHover(false)} >
                  <a href="/images/arrow.svg" download>
                     <div className="btn-outer-border" 
                     style={{backgroundColor: `${buttonBackground}`}} onMouseEnter={changeBackground} onMouseLeave={changeBackground}>
                        <div className="arrow-left">
                           <img src={fatArrow} alt="" />
                        </div>
                        <a href={resume} download="mgredler-portfolio.pdf" className={`resume-btn ${resumeBtnHover && "resume-btn-hover"}`} >
                           <img src={pdfIcon} alt="" />
                           <span className={`${resumeBtnHover && "resume-btn-span-hover"}`}>Download</span>
                        </a>
                        <div className="arrow-right">
                           <img src={fatArrow} alt="" />
                        </div>
                     </div>

                  </a>
               </div>
            </div>
         </div>
      </div>
   )
}

export default About;